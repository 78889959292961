exports.components = {
  "component---src-pages-ae-404-jsx": () => import("./../../../src/pages-ae/404.jsx" /* webpackChunkName: "component---src-pages-ae-404-jsx" */),
  "component---src-pages-ae-about-us-index-jsx": () => import("./../../../src/pages-ae/about-us/index.jsx" /* webpackChunkName: "component---src-pages-ae-about-us-index-jsx" */),
  "component---src-pages-ae-index-js": () => import("./../../../src/pages-ae/index.js" /* webpackChunkName: "component---src-pages-ae-index-js" */),
  "component---src-pages-ae-industries-financial-services-index-jsx": () => import("./../../../src/pages-ae/industries/financial-services/index.jsx" /* webpackChunkName: "component---src-pages-ae-industries-financial-services-index-jsx" */),
  "component---src-pages-ae-services-artificial-intelligence-ai-tools-for-business-transformation-index-jsx": () => import("./../../../src/pages-ae/services/artificial-intelligence/ai-tools-for-business-transformation/index.jsx" /* webpackChunkName: "component---src-pages-ae-services-artificial-intelligence-ai-tools-for-business-transformation-index-jsx" */),
  "component---src-pages-ae-services-artificial-intelligence-index-jsx": () => import("./../../../src/pages-ae/services/artificial-intelligence/index.jsx" /* webpackChunkName: "component---src-pages-ae-services-artificial-intelligence-index-jsx" */),
  "component---src-pages-ae-services-custom-software-development-index-jsx": () => import("./../../../src/pages-ae/services/custom-software-development/index.jsx" /* webpackChunkName: "component---src-pages-ae-services-custom-software-development-index-jsx" */),
  "component---src-pages-ae-services-design-studio-index-jsx": () => import("./../../../src/pages-ae/services/design-studio/index.jsx" /* webpackChunkName: "component---src-pages-ae-services-design-studio-index-jsx" */),
  "component---src-pages-ae-services-mobile-development-index-jsx": () => import("./../../../src/pages-ae/services/mobile-development/index.jsx" /* webpackChunkName: "component---src-pages-ae-services-mobile-development-index-jsx" */),
  "component---src-pages-ae-services-web-development-index-jsx": () => import("./../../../src/pages-ae/services/web-development/index.jsx" /* webpackChunkName: "component---src-pages-ae-services-web-development-index-jsx" */)
}

